$colors-list: (
  neutral: (
    1: #ffffff,
    2: #fafafa,
    3: #f0f0f0,
    4: #e6e6e6,
    5: #ededed,
    6: #bdbdbd,
    7: #9e9e9e,
    8: #6d6d6d,
    9: #4f4f4f,
    10: #171717,
    11: #f2f2f2,
    12:#E0E0E0,
    13: #171717,
    14: #d9d9d9, 
    15: #F3F2EF, 
  ),
  accent: (
    1: #0e5eb9,
    2: #214a8e,
    3: #08386d,
    4: #E0E3FA
  ),
  blue: (
    1: #eff5fb,
    2: #e6eff9,
    3: #1e8ce6,
    4: #1676c5,
    5: #1260a0,
    6: #D0F0FD,
    7: #CFDFFF,
    8: #1676C5,
    9: #E0EBFF,
    10: #DBF6FA,
    11: #F2F4F7,
  ),
  red: (
    1: #fceae9,
    2: #fae0de,
    3: #e24236,
    4: #df2d20,
    5: #c9291d,
    6: #D34022,
    7: #FCECE9,
    50: #FEF3F2,
    700: #B42318
  ),
  green: (
    1: #e9f3ed,
    2: #dbebe1,
    3: #249e57,
    4: #1e8549,
    5: #166437,
    6: #E6FBE0,
    50: #ECFDF3,
    700: #027A48
  ),
  orange: (
    1: #f7efe6,
    2: #ddbe96,
    3: #cea36b,
    4: #ab6100,
    5: #784400,
    6: #BD7E00,
    7: #e59900,
  ),
  yellow: (
    1: #FFEAB6,
    2: #FFF0CC,
    3: #FBF5DA
  ),
  pink: (
    1: #E6DCF9,
    2: #FBE4F4,
    3: #FBE4E0
  ),
  gray: (
    1: #828282,   
    100: #F2F4F7,
    700: #344054
  ),
  black: (
    1: "#282828",
    2: "#000"
  )
);

@each $baseColor, $variants in $colors-list {
  @each $variantName, $value in $variants {
    .bg-#{$baseColor}-#{$variantName} {
      background-color: #{$value} !important;
    }
    .border-color-#{$baseColor}-#{$variantName} {
      border-color: #{$value};
    }
    .svg-path-fill-#{$baseColor}-#{$variantName} {
      path {
        fill: #{$value};
      }    
    }
    .#{$baseColor}-#{$variantName} {
      color: #{$value} !important;
    }
  }
}

.black-3 {
  color: #171717;
}

.bg-white-2 {
  background-color: #F9F6F6;
}

.color-neutral-9 {
  color: #4f4f4f;
}

.neutral-light {
  color: #828282;
}

.bg-orange {
  background-color: orange;
}

.bg-white {
  background-color: white;
}

.bg-transparent {
  background-color: transparent;
}
